import React, { useState } from "react";
import { Redirect } from 'react-router-dom';
import {Link} from "@reach/router";

const NewPost = () => {
    const [title, setTitle] = useState([]);
    const [username, setUsername] = useState([]);
    const [headerImage, setHeaderImage] = useState([]);
    const [content, setContent] = useState([]);
    const [newPostLink, setNewPostLink] = useState("");

    const newPost = async e => {
        e.preventDefault()
        let image = headerImage || undefined;
        let post = {title, username, headerImage:image, content}

        const res = await fetch(
            `https://posts.lucakiebel.workers.dev/posts`,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify(post) // body data type must match "Content-Type" header
            }
        )
        const postRes = await res.json();
        if(!postRes.success) return console.error(postRes.error);
        console.log("postId: ",postRes.post.id)
        setNewPostLink(`/posts/${postRes.post.id}`);
    }

    return (
        <div>
            <h1>Add new Post</h1>
            <form>
                <label>
                    Title: <input type="text" value={title} onChange={e => setTitle(e.target.value)}/>
                </label><br/>
                <label>
                    Username: <input type="text" value={username} onChange={e => setUsername(e.target.value)}/>
                </label><br/>
                <label>
                    Header Image: <input type="text" value={headerImage} onChange={e => setHeaderImage(e.target.value)}/>
                </label><br/>
                <label>
                    Post Content: <br/>
                    <textarea name="content" id="content" cols="30" rows="10" value={content} onChange={e => setContent(e.target.value)}/>
                </label><br/>
                <button type="submit" onClick={newPost}>Submit Post</button>
            </form>
            <Link to={newPostLink} >Link to your new Post</Link>

        </div>
    );
};

export default NewPost;