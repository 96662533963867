import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Post = ({ id }) => {
    const [post, setPost] = useState({});
    const [commentUsername, setCommentUsername] = useState("");
    const [commentContent, setCommentContent] = useState("");

    const getPost = async () => {
        const resp = await fetch(
            `https://posts.lucakiebel.workers.dev/posts/${id}`
        );
        const postResp = await resp.json();
        setPost(postResp);
    };

    const addReaction = async emoji => {
        const res = await fetch(
            `https://posts.lucakiebel.workers.dev/reactions/${id}`,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({emoji}) // body data type must match "Content-Type" header
            }
        )
        const postRes = await res.json();
        setPost(postRes.updatedPost)
    }

    const handleReaction = async e => {
        e.preventDefault();
        console.log("New reaction", e.target.value)
        await addReaction(e.target.value);
        e.target.value = "";
    }

    const handleComment = async e => {
        e.preventDefault();
        await fetch(
            `https://posts.lucakiebel.workers.dev/comments/${id}`,
            {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                headers: {
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({username:commentUsername, content:commentContent}) // body data type must match "Content-Type" header
            }
        )
        await getPost();
        setCommentContent("");
        setCommentUsername("");
    }

    useEffect(() => {
        (async () => { await getPost();})();
    }, []);

    if (typeof post === "undefined" || post === null) return <div />;

    return (
        <div>
            <img src={post.headerImage} alt="random header" width={100}/>
            <h1>{post.title}</h1>
            <span fontSize={5}>by {post.username}</span>
            <p>{post.content}</p>
            <p>
                <em>Published {new Date(post.date).toLocaleString()}</em> <br/> Reactions: &nbsp;
                {post.reactions && Object.keys(post.reactions).map(emoji => {
                    const emojiCount = post.reactions[emoji];
                    return (
                    <span key={emoji}>{emoji}<sub>{emojiCount}</sub>&nbsp;</span>
                    )
                })}
                <input type="text" onChange={handleReaction} style={{width:12+"px"}} placeholder="👍"/>
            </p>
            <h2>Comments:</h2>
            <form>
                <label htmlFor="username">Username:</label><input type="text" value={commentUsername} onChange={e => setCommentUsername(e.target.value)} id="username"/><br/>
                <label htmlFor="comment">Comment:</label><textarea id="comment" value={commentContent} onChange={e => setCommentContent(e.target.value)}/>
                <button type="submit" onClick={handleComment}>Submit Comment</button>
            </form>
            {
                post.comments && post.comments.map(comment => (
                    <div style={{border:1+"px solid red"}}>
                        <h3>{comment.username}</h3>
                        <span fontSize={5}><em>commented {new Date(comment.date).toLocaleString()}</em></span>
                        <p>{comment.content}</p>
                    </div>
                ))
            }
            <p>
                <Link to="/">Go back</Link>
            </p>
        </div>
    );
};

export default Post;