import { Router } from "@reach/router";

import Posts from './components/posts'
import Post from './components/post'
import NewPost from "./components/newPost";

function App() {
    return (
        <Router>
            <Posts path="/" />
            <Post path="/posts/:id" />
            <NewPost path="/posts/new" />
        </Router>
    );
}

export default App;