import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
        const getPosts = async () => {
            const resp = await fetch(
                "https://posts.lucakiebel.workers.dev/posts"
            );
            const postsResp = await resp.json();
            setPosts(postsResp);
        };

        getPosts();
    }, []);

    return (
        <div>
            <h1>Posts</h1>
            {posts && posts.map((post) => (
                <div key={post.id}>
                    <h2>
                        <Link to={`/posts/${post.id}`}>{post.title}</Link>
                    </h2>
                    <span fontSize={5}>by {post.username} <br/> Reactions: {
                        post.reactions && Object.keys(post.reactions).map(emoji => {
                            const emojiCount = post.reactions[emoji];
                            return (
                                <span>{emoji}<sub>{emojiCount}</sub>&nbsp;</span>
                            )
                        })
                    } <br/> Comment count: {post?.comments?.length || 0} </span>
                </div>
            ))}
            <Link to={"/posts/new"}>Add new Post</Link>
        </div>
    );
};

export default Posts;
